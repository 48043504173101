import React, { type FC, useRef } from 'react';

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Button,
	List,
	ListItem,
	Box,
	ListIcon,
} from '@chakra-ui/react';
import { CloseIcon, CopyIcon } from '@chakra-ui/icons';
import { ModalCompoInterface } from '../Modals/ModalComponent';
import { useToast } from '@chakra-ui/react';

interface RowItem {
	title?: string,
	item?: string,
	className: string,
}
const DataModal: FC<ModalCompoInterface> = ({ isOpen, onClose, data }: any) => {
	const filesList = [];
	for (const i in data.files) {
		filesList.push(data.files[i]);
	}
	const filteredObject: any = filesList.find(item => item.type === 'folder');

	const toast = useToast();
	const inputRef: any = useRef(null);
	const handleButtonClick = () => {


		if (inputRef.current) {
			inputRef.current.select();
			document.execCommand('copy');
			toast({
				title: 'Link ...',
				description: 'Link Copied ....',
				status: 'success',
				duration: 700,
				isClosable: true,
			});
		}

	};

	const RowData : FC<RowItem> =({ title, item, className }) => {
		console.log('item', item);
		return (
			<ListItem display={'flex'}>
				<Box as={'strong'} minW={'200px'} display={'inline-block'}>{title}
					<Box as={'span'} marginLeft={'auto'} float={'right'} marginRight={'8px'}>:
					</Box>
				</Box>
				{title === 'Instagram' || title === 'YouTube' || title === 'SoundCloud' ? (
					<Box w={'100%'} textTransform={'lowercase'} className={className}>
						<a href={item} target="_blank" rel="noopener noreferrer">
							{item}
						</a>
					</Box>
				) : (
					<Box w={'100%'} textTransform={'capitalize'} className={className}>
						{item}
					</Box>
				) }
			</ListItem>
		);
	};
	return (

		<Modal isOpen={isOpen} onClose={onClose} size={'4xl'} >
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>User Data</ModalHeader>
				<Button onClick={onClose} position={'absolute'} right={'-10px'} top={'-10px'} color={'#fff'} bg={'#000'} width={'40px'} height={'40px'} borderRadius={'50%'} _hover={{ bg: '#000000' }}><CloseIcon /></Button>
				<ModalBody>
					<List className='user_data_list' spacing={3}>
						<RowData className='captial' title='Name' item={data?.realName} ></RowData>
						<RowData className='captial' title='Artist Name' item={data?.artistName} ></RowData>
						<RowData className='captial' title='Phone Number' item={data?.phoneNumber} ></RowData>
						<RowData className='captial' title='Whatsapp Number' item={data?.whatsappNumber} ></RowData>
						<RowData className='small' title='Email' item={data?.email} ></RowData>
						<RowData className='captial' title='Artist Role' item={data?.artistRole} ></RowData>
						<RowData className='captial'title='Gender' item={data?.gender} ></RowData>
						<RowData className='captial'title='Nationality' item={data?.nationality} ></RowData>
						<RowData className='captial'title='City/Location' item={data?.cityLocation} ></RowData>
						<RowData className='captial' title='Fee' item={'$' + data?.fee} ></RowData>
						{data?.soundCloud && <RowData className='small' title='SoundCloud' item={data?.soundCloud} ></RowData>}
						{data?.instruments && <RowData className='captial' title='Instruments' item={data?.instruments} ></RowData>}
						{data?.youTube && <RowData className='small' title='YouTube' item={data?.youTube} ></RowData>}
						{data?.musicGenre && <RowData className='captial' title='Music Genre' item={data?.musicGenre} ></RowData>}
						{data?.language && <RowData className='captial' title='Language' item={data?.language} ></RowData>}
						<RowData className='small' title='Instagram' item={data?.instagram} ></RowData>
						<ListItem display={'flex'}>
						 	<Box as={'strong'} minW={'200px'} display={'inline-block'}>Folder Link
								<Box as={'span'} marginLeft={'auto'} float={'right'} marginRight={'8px'}>:
								</Box>
							</Box>
							{filteredObject && (
								<>
									<input
										type="text"
										readOnly
										value={filteredObject.link}
										ref={inputRef}
										style={{ position: 'absolute', left: '-9999px' }}
									/>
									<a href={filteredObject.link} target="_blank" rel="noreferrer" style={{ color: '#000' }}>
										{filteredObject.link}
									</a>
									<ListIcon as={CopyIcon} color='#000' marginBottom={'6px'} marginLeft={'10px'} onClick={handleButtonClick} cursor={'pointer'} />
								</>
							)}
						</ListItem>
					</List>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default DataModal;