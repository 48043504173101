// const API_URL = 'your-api-endpoint';
import CONSTANT from '../Constant';

export const addArtist = (data: any) => {
	return fetch(`${CONSTANT.API_URL}/create-user`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			const res = response.json();
			return res;
		})
		.catch((error: any) => {
			console.log('api error', error);
			return error;
		});
};

export const updatePresentation = (artistId: any, data: any) => {
	return fetch(`${CONSTANT.API_URL}/updatePresentation/${artistId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
		.then(response => {
			const res = response.json();
			return res;
		})
		.catch((error: any) => {
			console.log('error in presentation', error);
		});
};

export const _getPublicLink = (userId: any, documentId: any) => {
	return fetch(`${CONSTANT.API_URL}/generateLink/${userId}/${documentId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
		.then(response => console.log('generate link res', response.json()))
		.catch(error => {
			throw new Error(error);
		});
};

export const _getRefreshToken = async() => {
	try {
		const response = await fetch(`${CONSTANT.API_URL}/generateToken`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
		});

		const result = await response.json();
		localStorage.setItem('refreshToken', result.token);
	} catch (error) {
		console.log('errr', error);
	}
};
