import React, { type FC } from 'react';
import { useState } from 'react';
import { AspectRatio, Box, ListItem, ModalHeader, OrderedList, Spinner, useToast } from '@chakra-ui/react';
import { ImageSliderInterface } from '../Modals/ImageSliderComponent';
import { downloadFile } from '../utils/firebase';
import {
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
const ImagesSliderModal: FC<ImageSliderInterface> = ({ isOpen, onClose, files }: any) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [loader, setLoader] = useState(false);
	const toast = useToast();
	const handlePrevious = () => {
		setCurrentImageIndex((currentImageIndex + 1) % files.length);
	};

	const handleNext = () => {
		setCurrentImageIndex((currentImageIndex - 1 + files.length) % files.length);
	};

	const handleDownload = async() => {
		try {
			setLoader(true);
			const fileType = getFileType(files[0].type);
			const response = await downloadFile(files[0].sku, fileType);
			setLoader(false);
			if (response) {
				toast({
					title: 'Success',
					description: 'Download Successfully ....',
					status: 'success',
					duration: 700,
					isClosable: true,
				});
			}
		} catch (error) {
			setLoader(false);
			console.log('handleDownload error', error);
			toast({
				title: 'Failed',
				description: 'Download Failed ....',
				status: 'error',
				duration: 700,
				isClosable: true,
			});
		}
	};

	const getFileType = (fileType: any) => {
		switch (fileType) {
			case 'doc':
				return 'document';
			case 'photo':
				return 'image';
			case 'video':
				return 'video';
			default:
				return '';
		}
	};

	const renderContent = () => {
		if (files[currentImageIndex].type === 'photo') {
		  return <Image width={'100%'} height={'400px'} objectFit={'contain'} src={files[currentImageIndex].link} alt={`Image ${currentImageIndex}`} />;
		}
		if (files[currentImageIndex].type === 'video') {
		  return (
				<AspectRatio height={'400px'} maxW='600px' margin={'auto'} ratio={1}>
					<iframe
						title={`Video ${currentImageIndex}`}
						src={files[currentImageIndex].link}
						frameBorder={'1'}
					/>
				</AspectRatio>
		  );
		}
		  return (<Box>
			<ModalHeader>Document Links</ModalHeader>
			<OrderedList>
				{files.map((item: any, index: number) => (
					<ListItem key={index}>
						<a href={item.link} target="_blank" style={{ color: '#000' }} rel="noreferrer">
							{decodeURI(item.fileName)}
						</a>
					</ListItem>
				))}
			</OrderedList>
		</Box>
				 );
	  };

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent>
				<Button onClick={onClose} position={'absolute'} right={'-10px'} top={'-10px'} color={'#fff'} bg={'#000'} width={'40px'} height={'40px'} borderRadius={'50%'} _hover={{ bg: '#000000' }}><CloseIcon /></Button>
				<ModalBody>
					<Box  alignItems={'center'}>
						 {renderContent()}
					</Box>
				</ModalBody>
				<ModalFooter justifyContent="space-between">
					{files[currentImageIndex].type === 'doc' ?
						('') : (<Button className='previous' onClick={handlePrevious}>
							Previous
						</Button>)
					}
					<Button onClick={handleDownload}>
            			Downlaod Folder
					</Button>
					{files[currentImageIndex].type === 'doc' ?
						('') : (<Button className='next' onClick={handleNext}>
							Next
						</Button>)
					}
				</ModalFooter>
			</ModalContent>
			{loader && (
				<Box sx={{
					position: 'fixed',
					top: '0',
					left: '0',
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backdropFilter: 'brightness(70%)',
					zIndex: 9999,
				}}>
					<Spinner  thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='blue.500'
						size='xl' />
				</Box>
			)}
		</Modal>
	);
};

export default ImagesSliderModal;
