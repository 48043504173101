/* eslint-disable no-useless-escape */
import * as Yup from 'yup';


export const userInfoSchema = Yup.object().shape({

	gender: Yup.string().required('This field is required'),
	youTube: Yup.string()
		.test('regex', 'Invalid URL.', (value) => {
			if (!value) {
				return true;
			}
			const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)(\?[\w=&%-]*)?\/?$/;
			return regex.test(value);
		}),
	artistRole: Yup.string().required('This field is required'),

	soundCloud : Yup.string().when('artistRole', ([artistRole], schema) => {
		if (artistRole === 'DJ')
			return Yup.string().required('This field is required')
				.test('regex', 'Invalid URL.', (value) => {
					const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)(\?[\w=&%-]*)?\/?$/;
					return regex.test(value);
			  });
		return schema;
	}),

	otherArtist: Yup.string().when('artistRole', ([artistRole], schema) => {
		if (artistRole === 'Other')
			return Yup.string().required('This field is required');
		return schema;
	}),

	instruments: Yup.string().when('artistRole', ([artistRole], schema) => {
		if (artistRole === 'Musician')
			return Yup.string().required('This field is required');
		return schema;
	}),

	otherInstrument: Yup.string().when('instruments', ([instruments], schema) => {
		if (instruments === 'Other')
			return Yup.string().required('This field is required');
		return schema;
	}),

	// musicGenre: Yup.string().when('instruments', ([instruments], schema) => {
	// 	if (instruments === 'Band' || instruments === 'Singer')
	// 		return Yup.string().required('This field is required');
	// 	return schema;
	// }),

	musicGenre: Yup.string().when(['instruments', 'artistRole'], ([instruments, artistRole], schema) => {
		if (artistRole === 'DJ') {
			return Yup.string().required('This field is required');
		}
		// For other roles, validate based on instruments
		if (instruments === 'Band' || instruments === 'Singer') {
			return Yup.string().required('This field is required');
		}
		return schema;
	}),

	otherMusicGenre: Yup.string().when('musicGenre', ([musicGenre], schema) => {
		if (musicGenre === 'other')
			return Yup.string().required('This field is required');
		return schema;
	}),
	// otherMusicGenre: Yup.string().required('This field is required'),
	language: Yup.string().when('instruments', ([instruments], schema) => {
		if (instruments === 'Band' || instruments === 'Singer')
			return Yup.string().required('This field is required');
		return schema;
	}),

	otherLanguages: Yup.string().when('language', ([language], schema) => {
		if (language === 'other')
			return Yup.string().required('This field is required');
		return schema;
	}),
	email: Yup.string()
		.email().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'email must be a valid email')
		.required('This field is required'),
	artistName: Yup.string()
		.required('This field is required'),
	nationality: Yup.string()
		.required('This field is required'),
	realName: Yup.string()
		.required('This field is required'),
	whcountryCode: Yup.string().when('whatsappNumber', ([whatsappNumber], schema) => {
		if (whatsappNumber !== '' && whatsappNumber !== undefined)
			return Yup.string().required('This field is required');
		return schema;
	}),
	countryCode: Yup.string().nullable().matches(/^\+\d{2,}$/, 'Please enter a valid country code starting with "+" and followed by at least two digits')
		.required('This field is required'),

	phoneNumber: Yup.string().matches(/^(((\+[1-9]{1,4}[ \\-]*)|(\([0-9]{2,3}\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?)$|^[\d()+-]+$/, 'Phone number is not valid')
		.min(9, 'too short')
		.max(15, 'too long')
		.required('This field is required'),
	whatsappNumber: Yup.string().matches(/^(((\+[1-9]{1,4}[ \\-]*)|(\([0-9]{2,3}\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?)$|^[\d()+-]+$/, 'Whatsapp number is not valid')
		.min(9, 'too short')
		.max(15, 'too long'),
	cityLocation: Yup.string()
		.required('This field is required'),
	// fee:  Yup.string()
	// 	.required('This field is required'),

	instagram: Yup.string()
		.required('This field is required')
		.test(
			'valid-instagram-username-or-url',
			'Please enter a valid Instagram username or URL.',
			(value) => {
			  // Username validation:
			  const usernameRegex = /^@[a-zA-Z0-9._-]{1,30}$/;
			  if (usernameRegex.test(value)) {
					return true; // Valid username
			  }

			  // URL validation:
			  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)(\?[\w=&%-]*)?\/?$/;
			  if (urlRegex.test(value)) {
					return true; // Valid URL
			  }
				//   console.log('username validate', usernameRegex.test(value));
				//   console.log('url validate', urlRegex.test(value));
			  return false;
			}
		),

	termsAndConditions: Yup
		.bool()
		.oneOf([true], 'You need to accept the terms and conditions'),
	// agreement: Yup.string()
	// 	.required('Please accept the terms and condition')
	documents: Yup.string().min(1).required('At least one document is required'),
	images: Yup.string().min(1).required('At least one image/video is required'),
});