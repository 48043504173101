
const answer = 'Your answer';
const formInput = [
	{
		id:'artistName',
		label:'What is your Artist/Act name? *',
		name :'artistName',
		placeholder : answer,
		type : 'text'
	},
	{
		id:'realName',
		label:'What is your real Name? *',
		name :'realName',
		placeholder : answer,
		type : 'text'
	},
	{
		id:'email',
		label:'What is your e-mail address? *',
		name :'email',
		placeholder :  answer,
		type : 'text'
	},
	{
		id:'phoneNumber',
		label:'What is your phone number? *',
		name :'phoneNumber',
		placeholder : answer,
		type : 'text'
	},
	{ id:'whatsappNumber',
		label:'What is your whatsapp number? *',
		name :'whatsappNumber',
		placeholder : answer,
		type : 'text'

	},
	{ id:'cityLocation',
		label:'Your current City/Location *',
		name :'cityLocation',
		placeholder : answer,
		type : 'text'

	},
	{   id:'fee',
		label:'Expected fee per gig/act in AED *',
		name :'fee',
		placeholder : answer,
		type : 'text'

	}
];

export const initialValues = {
	gender: '',
	artistRole: '',
	instruments: '',
	otherInstrument: '',
	email: '',
	nationality: '',
	artistName: '',
	realName: '',
	countryCode: '',
	whcountryCode: '',
	phoneNumber: '',
	whatsappNumber: '',
	cityLocation: '',
	musicGenre: '',
	otherMusicGenre: '',
	language: '',
	otherLanguages: '',
	fee: '',
	soundCloud: '',
	instagram: '',
	youTube: '',
	documents: '',
	images: '',
	otherArtist: '',
	termsAndConditions: false,
	// gender: '',
	// artistRole: 'artistRole',
	// instruments: 'artistRole',
	// otherInstrument: 'artistRole',
	// email: 'artistRole@gmail.com',
	// nationality: 'artistRole',
	// artistName: 'artistRole update two',
	// realName: 'artistRole update two',
	// countryCode: '33',
	// phoneNumber: '9876543210',
	// whatsappNumber: '9876543210',
	// cityLocation: 'artistRole',
	// language: 'artistRole',
	// fee: '987',
	// soundCloud: 'artistRole',
	// instagram: 'artistRole',
	// youTube: 'artistRole',
	// documents: '',
	// images: '',
	// agreement: ''
};

export const roleOption = [
	'DJ', 'Musician', 'Magician/Illusionist', 'Dancer', 'Belly dancer', 'Fire dancer', 'Acrobat', 'Aerial act', 'Special Act', 'Choreographer', 'Host/MC', 'Other'
];
export const djOptions = [
	'open format ', 'Commercial', 'House', 'Afrobeat/Amapiano', 'HipHop, R&B', 'Latin', 'Jazzy', 'Funk/Soul', 'Disco', 'Downtempo', 'Techno', 'Minimal'
];

export const MusicGenreOptions = [
	'Blues', 'Country', 'Funk', 'Jazz', 'Latin', 'Pop', 'R&B/Soul', 'Rock', 'other'
];

export const countryCodeOption = [
	''
];

// export const LanguagesOptions = [
// 	'Arabic', 'English', 'French', 'Italian', 'Portuguese', 'Russian', 'Spanish', 'other'
// ];
export const role = [
	{ id: 1, label: 'DJ' },
	{ id: 2, label: 'Musician' },
	{ id: 3, label: 'Magician/Illusionist' },
	{ id: 4, label: 'Dancer' },
	{ id: 5, label: 'Belly dancer' },
	{ id: 6, label: 'Fire dancer' },
	{ id: 7, label: 'Acrobat' },
	{ id: 8, label: 'Aerial Act' },
	{ id: 9, label: 'Special Act' },
	{ id: 10, label: 'Other' },
];
export const MusicGenreOptionsNew = [
	{ id: 1, label: 'Blues' },
	{ id: 2, label: 'Country' },
	{ id: 3, label: 'Funk' },
	{ id: 4, label: 'Jazz' },
	{ id: 5, label: 'Latin' },
	{ id: 6, label: 'Pop' },
	{ id: 7, label: 'R&B/Soul' },
	{ id: 8, label: 'Rock' },

];
export const djOptionsNew = [
	{ id: 1, label : 'Open format' },
	{ id: 2, label : 'Commercial' },
	{ id: 3, label : 'House' },
	{ id: 4, label : 'Afrobeat/Amapiano' },
	{ id: 5, label : 'HipHop, R&B' },
	{ id: 6, label : 'Commercial Latin' },
	{ id: 7, label : 'Jazzy' },
	{ id: 8, label : 'Funk/Soul' },
	{ id: 9, label : 'Disco' },
	{ id: 10, label : 'Downtempo' },
	{ id: 11, label : 'Latin' },
	{ id: 12, label : 'Techno' },
	{ id: 13, label : 'Minimal' },
];

export const musicianOptionNew = [
	{ id: 1, label : 'Band' },
	{ id: 2, label : 'Singer' },
	{ id: 3, label : 'Saxophonist' },
	{ id: 4, label : 'Violonist' },
	{ id: 5, label : 'Percussionist' },
	{ id: 6, label : 'Guitarist' },
	{ id: 7, label : 'Cellist' },
	{ id: 8, label : 'Oud' },
	{ id: 9, label : 'Pianist' },
	{ id: 10, label : 'Flute' },
	{ id: 11, label : 'Trumpet' },
	{ id: 12, label : 'Base' },
	{ id: 13, label : 'Harp' },
];
export const languageOptionNew = [
	{ id: 1, label : 'Arabic' },
	{ id: 2, label : 'English' },
	{ id: 3, label : 'French' },
	{ id: 4, label : 'Italian' },
	{ id: 5, label : 'Portuguese' },
	{ id: 6, label : 'Russian' },
	{ id: 7, label : 'Spanish' }
];
export const musicianOption = [
	'Band', 'Singer', 'Saxophonist', 'Violonist', 'Percussionist', 'Guitarist', 'Cellist', 'Oudist,', 'Pianist', 'Flutist', 'Trumpeter', 'Bassist', 'Harpist', 'Other'
];
export const language = [
	'Arabic',
	'English',
	'Urdu',
	'Hindi',
	'Tagalog (Filipino)',
	'Bengali',
	'Malayalam',
	'Tamil',
	'Persian (Farsi)',
	'Chinese (Mandarin)',
	'other'
];
export const genderOption = [
	'Female', 'Male', 'Choose not to share'
];
export const genderOptions = [
	'All', 'Female', 'Male', 'Others'
];

export const marqueeListItems = [
	'Jazz Band',
	'Deep House DJ',
	'Guitarist',
	'Afrobeat/Amapiano',
	'Acrobat',
	'Magician',
	'Dj',
	'Saxophone',
	'Jazz',
	'Violin',
	'Musician',
	'Trumpet',
	'Soul',
	'Funk',
	'Singer',
	'Blues',
	'Dancer',
	'House',
	'Band',
	'Lounge',
	'Stilt walker',
	'Groovy',
	'Rock',
	'Percussionist',
	'Fire show',
	'Choreographer',
];

export default formInput;