/* eslint-disable no-unsafe-optional-chaining */
import React, { FC } from 'react';
import { Container, Box, Text, Image } from '@chakra-ui/react';
import FooterComponent from '../../Components/FooterComponent';
import HeaderComponent from '../../Components/HeaderComponent';
import formSuccessImage from '../../assets/images/clickbeat_form_success.png';
import '../../style/form.css';
import '../../style/success.css';
const SuccessPage: FC = () => {

	return (
		<>
			<HeaderComponent />
			<Box >
				<Container maxW='1280px' display='flex' flexDirection='column' alignItems='center' py={['50px', '50px']}>
					<Box textAlign={'center'}>
						<Text fontFamily={'Optima LT Pro'} fontSize={'24px'} fontWeight={'400'} lineHeight={'30px'} color={'#F60068'} paddingBottom={'20px'}>Congratulations</Text>
					</Box>
					<Box justifyContent={'center'}>
						<Text marginBottom={'5px'} className='success-title' textAlign={'center'} fontFamily={'Optima LT Pro'} fontWeight={'400'} lineHeight={'50px'}>Thank you for signing up with us.</Text>
						<Text className='success-title' textAlign={'center'} fontFamily={'Optima LT Pro'} fontWeight={'400'} lineHeight={'58px'}>We will get in touch</Text>
					</Box>
				</Container>
				<Image
					src={formSuccessImage}
					width={'100%'}
					// maxW={['260px', '280px', '310px']}
					alt='Logo'
				/>
			</Box>
			<FooterComponent />
		</>
	);
};

export default SuccessPage;

