
const CONSTANT = {
	APIKEY: 'AIzaSyC2B6PU0hrr8bNZHgh7D-kR46NSlAgz8OU',
	AUTHDOMAIN: 'clicbeat-form-manager.firebaseapp.com',
	PROJECTID: 'clicbeat-form-manager',
	STORAGEBUCKET: 'clicbeat-form-manager.appspot.com',
	MESSAGINGSENDERID: '649809646740',
	APPID: '1:649809646740:web:cd9e423d3bb20cc1acd081',
	API_URL: process.env.REACT_APP_API_URL,
	// API_URL: 'http://dev-zgv2.hupp.in:7796/api',
	APP_KEY: '57v5ib54qhaajwz:txs6p0wfco97fb1',
	GENRATE_TOKEN: 'https://asia-south1-clicbeat-form-manager.cloudfunctions.net/generateToken',
	GET_PUBLIC_LINK:'https://asia-south1-clicbeat-form-manager.cloudfunctions.net/generateLink',
	FILE_UPLOAD: 'https://content.dropboxapi.com/2/files/upload',
};
export default CONSTANT;