import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import '../style/form.css';
import BurjArabLogo from '../assets/images/Group 2.png';
// import SkyManagementLogo from '../assets/images/sky-management.svg';
import FourSeasonsLogo from '../assets/images/four-seasoms.svg';
import SLSLogo from '../assets/images/Rectangle-2.png';
import JumerihLogo from '../assets/images/Rectangle.png';
import RitzLogo from '../assets/images/ritz.svg';
import WLogo from '../assets/images/w-logo 1.png';
import MarriotLogo from '../assets/images/2560px-Marriott_Logo 1.png';
import MeliaLogo from '../assets/images/melia-hotels-international-logo-vector 1.png';
// import { ReactSVG } from 'react-svg';

const ClientLogoComponent = () => {

	return (
		<Flex alignItems='center' gap='4' justifyContent='space-around' py={['30px', '60px', '60px']} px={['20px', '0', '0']} flexWrap={'wrap'}>
			<Box>
				<Image src={BurjArabLogo} />
			</Box>
			<Box>
				<Image src={FourSeasonsLogo} />
			</Box>
			<Box>
				<Image src={SLSLogo} />
			</Box>
			<Box>
				<Image src={RitzLogo} />
			</Box>
			<Box>
				<Image src={JumerihLogo} />
			</Box>
			<Box>
				<Image src={MeliaLogo} />
			</Box>
			<Box>
				<Image src={WLogo} />
			</Box>
			<Box>
				<Image src={MarriotLogo} />
			</Box>
		</Flex>
	);
};
export default ClientLogoComponent;