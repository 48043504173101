import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import { Box, Image, List, ListItem, Text, Heading } from '@chakra-ui/react';
import footerLogo from '../assets/images/second-logo.png';
import '../style/footer.css';

const FooterComponent = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
		  setIsMobile(window.innerWidth < 768);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	  }, []);

	return (
		<Box className='footer'>
			<Box className='footer-left'>
				<Box className='footer-logo'>
					<Link to='https://clicbeat.com'>
						<Image
							src={footerLogo}
							width={'100%'}
							maxW={['260px', '280px', '310px']}
							alt='Logo'
						/>
					</Link>
				</Box>
				<Box className='footer-menu'>
					{
						!isMobile &&
						<List className='footer-page-link-ul'>
							<ListItem>
								<Link to='https://clicbeat.com/music'>Music</Link>
							</ListItem>
							<ListItem>
								<Link to='https://clicbeat.com/entertainment'>Entertainment</Link>
							</ListItem>
							<ListItem>
								<Link to='https://clicbeat.com/terms-conditions/'>Terms &amp; Conditions</Link>
							</ListItem>
						</List>
					}
					<Text className='copyright-text'>2024 CLICBEAT All rights reserved</Text>
				</Box>
			</Box>
			<Box className='footer-right'>
				<Heading as='h3'><Link to="mailto:hello@clicbeat.com">Send us a message</Link> to chat about your business, no strings attached.</Heading>
				<List className='social-media-ul'>
					<ListItem>
						<Link to='https://www.instagram.com/clicbeat/'>Instagram</Link>
					</ListItem>
					<ListItem>
						<Link to='https://www.linkedin.com/company/clicbeat'>LinkedIn</Link>
					</ListItem>
					<ListItem>
						<Link to='https://soundcloud.com/clicbeatmusic'>SoundCloud</Link>
					</ListItem>
				</List>
				{
					isMobile && <Box className='footer-menu mobile'>
						<List className='mobile-page-links'>
							<ListItem>
								<Link to='https://clicbeat.com/music'>Music</Link>
							</ListItem>
							<ListItem>
								<Link to='https://clicbeat.com/entertainment'>Entertainment</Link>
							</ListItem>
							<ListItem>
								<Link to='https://clicbeat.com/terms-conditions/'>Terms &amp; Conditions</Link>
							</ListItem>
						</List>
					</Box>
				}

			</Box>
		</Box>
	);
};
export default FooterComponent;