import React from 'react';
import InputFeedback from './InputFeedback';
import { RadioButtonGroupInterface } from '../Modals/RadioButtonGroup';
import { Text, Flex } from '@chakra-ui/react';

const RadioButtonGroup = ({
	error,
	touched,
	label,
	children
}: RadioButtonGroupInterface) => {
	return (

		<><Text as="label" fontWeight={500} color='#000' lineHeight={['10px', '10px', '10px']} fontSize={['14px', '16px', '16px']} mb='15px' display={{ base: 'inline-block' }} >{label}</Text>
			<Flex alignItems='center' flexWrap={'wrap'}>
				{children}
				{touched && <InputFeedback error={error} />}
			</Flex></>

	);
};
export default RadioButtonGroup;