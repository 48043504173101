import React, { type FC } from 'react';

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	Button,
	Image,
	Heading,
	Box
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import successGif from '../assets/images/successfully-done_1.gif';
import { ModalCompoInterface } from '../Modals/ModalComponent';
import siteLogo from '../assets/images/logo.png';

const ResponseModal: FC<ModalCompoInterface> = ({ isOpen, onClose }: any) => {

	return (

		<Modal isOpen={isOpen} onClose={onClose} size={['xs', 'xl', 'xl']} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalBody>
					<Box className='modal-logo'>
						<Link to='/'>
							<Image
								src={siteLogo}
								width={'100%'}
								maxW={['260px', '280px', '310px']}
								alt='Logo'
							/>
						</Link>
					</Box>
					<Image src={successGif} boxSize='200px' objectFit='cover' alt='success image' margin={'auto'} />
					<Heading as={'h4'} textAlign={'center'} fontSize={['22', '26', '28']} color={'#000'}>Congratulation! <br /> You have registered successfully</Heading>
				</ModalBody>
				<ModalFooter justifyContent={'center'}>
				    <Button onClick={onClose} colorScheme='red' variant='outline'>Close</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
export default ResponseModal;