import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import CONSTANT from '../Constant';
import { addArtist, _getPublicLink, _getRefreshToken } from './api';
import saveAs from 'file-saver';

const config = {
	apiKey:  CONSTANT.APIKEY,
	authDomain: CONSTANT.AUTHDOMAIN,
	projectId: CONSTANT.PROJECTID,
	storageBucket: CONSTANT.STORAGEBUCKET,
	messagingSenderId: CONSTANT.MESSAGINGSENDERID,
	appId: CONSTANT.APPID
};

export const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);

export let indexId = 0;
export const addData = async(data: any, images: Array<any>, documents: Array<any>) => {
	_getRefreshToken();
	indexId = images.length;
	if (data.gender === 'choose not to share') {
		data.gender = 'not-shared';
	}
	try {
		// add artist data into postgres
		const insertResult: any = await addArtist(data);
		if (insertResult.status === 200) {
			const refreshToken = localStorage.getItem('refreshToken');
			   await _fileUpload(images, insertResult.data.sku, 0, documents, refreshToken,  insertResult.data.id);
			   await _documentUpload(documents, insertResult.data.sku, images, 0, refreshToken, insertResult.data.id);
			   await _getPublicLink(insertResult.data.sku, insertResult.data.id);
		   const response = 'success';
		   return response;
		} else {
			const response = insertResult.message[0].message;
			return response;
		}
	} catch (e: any) {
		  const errorMessage = e || 'Unknown error';
		  console.error('Error adding document: ------------', errorMessage);
		  return errorMessage;
	}
};

// async function getLastAddedRecord() {
// 	const q = query(collection(db, 'Users'), orderBy('createdTime', 'desc'), limit(1));
// 	const querySnapshot = await getDocs(q);

// 	if (!querySnapshot.empty) {
// 	  const documentSnapshot = querySnapshot.docs[0];
// 	  const documentData = documentSnapshot.data();
// 	  console.log('documentData', documentData);
// 	  return documentData.sku;
// 	} else {
// 	  console.log('No records found.');
// 	}
// }

const onUploaderResponse = async(res: any, index: number, images: Array<any>, id : string, documents: Array<any>, docId: string) => {
	if (images.length > index){
		if (res === false){
			return;
		} else {
			const refreshToken = localStorage.getItem('refreshToken');
			await _fileUpload(images, id, index, documents, refreshToken, docId);
		}
	}
};
const onDocUploaderResponse = async(res: any, index:number, documents: Array<any>, id: string,  images: Array<any>, docId: string) => {
	indexId = index;
	if (documents.length > index){
		if (res?.success === false){
			return;
		} else {
			const refreshToken = localStorage.getItem('refreshToken');
			await _documentUpload(documents, id, images, index, refreshToken, docId);
		}
	}
};

const _fileUpload = async(images: Array<any>, id: string, index: number, documents: Array<any>, refreshToken: any, docId: string) => {
	if (images.length > 0) {
		indexId = index;
		if (images[index] !== undefined || images[index] !== 'undefined' || images[index] !== null){

			const formData = new FormData();
			formData.append('files', images[index]);
			formData.append('userId', id);
			formData.append('documentId', docId);
			formData.append('fileType', 'files');

			await callImageUpload(formData, index, id, images, documents, refreshToken, docId);
		}

	}
};
const _documentUpload = async(documents: any, id: string, images : any, index: number, refreshToken: any, docId: string) => {
	if (documents.length > 0) {
		indexId = index;
		if (documents[index] !== undefined || documents[index] !== 'undefined' || documents[index] !== null){
			const formData = new FormData();
			formData.append('files', documents[index]);
			formData.append('userId', id);
			formData.append('documentId', docId);
			formData.append('fileType', 'documents');
			await callDocUpload(formData, index, id, images, documents, refreshToken, docId);
		}
	}
};

export const callDocUpload =  async(  params: object, index: number, id: string, images : any, documents: any, refreshToken: any, docId: string) => {
	const myHeaders = new Headers();
	const obj = {
		'path' : '/'+`${id}/document/${documents[index].name}`,
	};
	myHeaders.append('Authorization', `Bearer ${refreshToken}`);
	myHeaders.append('Content-Type', 'application/octet-stream');
	myHeaders.append('Dropbox-API-Arg', JSON.stringify(obj) );

	const requestOptions: any= {
		method: 'POST',
		headers: myHeaders,
		body: documents[index],
		redirect: 'follow'
	};
	indexId = index;
	try {
		const res =	await fetch(CONSTANT.FILE_UPLOAD, requestOptions);
		console.log('resssssssssssssssssssssss docs', res);
		onDocUploaderResponse(true, index+1, documents, id, images, docId);
	} catch (error) {
		console.log(error);
	}

};

export const callImageUpload =  async(  params: object, index: number, id: string, images: any, documents: any, refreshToken: any, docId: string) => {
	const extractType = images[index].type;
	const fileType = extractType.split('/');
	const myHeaders = new Headers();
	const obj = {
		'path' : '/'+`${id}/${fileType[0]}/${images[index].name}`,
	};
	myHeaders.append('Authorization',  `Bearer ${refreshToken}`);
	myHeaders.append('Content-Type', 'application/octet-stream');
	myHeaders.append('Dropbox-API-Arg', JSON.stringify(obj) );

	indexId = index;

	_returnId(indexId);
	const requestOptions : any = {
		method: 'POST',
		headers: myHeaders,
		body: images[index],
		redirect: 'follow'
	};
	console.log('req', requestOptions);
	try {
		const res =	await fetch(CONSTANT.FILE_UPLOAD, requestOptions);
		console.log('resssssssssssssssssssssss imgssss', res);
		onUploaderResponse(true, index+1, images, id, documents, docId);
	} catch (error) {
		console.log(error);
	}
};

export const downloadFile = async(sku: string, fileType: string) => {
	_getRefreshToken();
	const myHeaders = new Headers();
	const obj = {
		'path' : `/${sku}/${fileType}`,
	};
	const refreshToken = localStorage.getItem('refreshToken');
	myHeaders.append('Authorization',  `Bearer ${refreshToken}`);
	myHeaders.append('Dropbox-API-Arg', JSON.stringify(obj) );

	const requestOptions : any = {
		method: 'POST',
		headers: myHeaders,
	};
	console.log('req', requestOptions);
	try {
		const response = await fetch('https://content.dropboxapi.com/2/files/download_zip', requestOptions);
		if (response.ok) {
			const result = await response.blob();
			saveAs(result, `${fileType}.zip`);
			return result;
		} else {
			throw new Error(response.statusText);
		}
	} catch (error) {
		console.log('download error', error);
		return error;
	}
};

// export const _getPublicLink = async(id: string, docId: string) => {
// 	const requestOptions : any = {
// 		method: 'GET',
// 		redirect: 'follow'
// 	  };

// 	  fetch(`https://asia-south1-clicbeat-form-manager.cloudfunctions.net/generateLink?userId=${id}&documentId=${docId}`, requestOptions)
// 		.then(response => response.text())
// 		.then(res => console.log('link', res))
// 		.catch(error => console.log('error', error));
// };

// export const _getRefreshToken = async() => {

// 	const requestOptions: any = {
// 		method: 'POST',
// 		redirect: 'follow'
// 	  };

// 	  fetch('https://asia-south1-clicbeat-form-manager.cloudfunctions.net/generateToken', requestOptions)
// 		.then(response =>
// 			 response.json()
// 		)
// 		.then(result => localStorage.setItem('refreshToken', result.token))
// 		.catch(error => console.log('error', error));
// };

export const insertPreFix = async(lastId: number) => {
	await setDoc(
		doc(db, 'prefix', 'prefix'),
		{
			count: lastId + 1
		},
		{
			merge: true
		});
};

export const _returnId = (id: any) => {
	return id;
};
export default { app, auth, db };