import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import NetworkIcon from '../assets/images/network-icon.svg';
import TouchIcon from '../assets/images/keep-in-touch-icon.svg';
import EventIcon from '../assets/images/events-icon.svg';
import '../style/form.css';
import { ReactSVG } from 'react-svg';

const IconBoxComponent = () => {

	return (
		<Box className='borderBottom'>
			<SimpleGrid columns={{ base: 1, md: 3 }}>
				<Box className='icon_box border_right'>
					<Box pt={10} pb={'44px'}>
						<ReactSVG src={NetworkIcon} />
						<Text mt={6} >Tell us about yourself to understand the fit</Text>
					</Box>
				</Box>
				<Box className='icon_box border_right' pl={'68px'} pr={'20px'}>
					<Box pt={10} pb={'44px'}>
						<ReactSVG src={EventIcon} />
						<Text mt={6} >Tap into our regional events across multiple countries from Dubai to Maldives</Text>
					</Box>
				</Box>
				<Box className='icon_box' pl={'68px'} pr={'50px'}>
					<Box pt={10} pb={'44px'}>
						<ReactSVG src={TouchIcon} />
						<Text mt={6} >We’ll get in touch with you for potential gigs</Text>
					</Box>
				</Box>
			</SimpleGrid>
		</Box>
	);
};
export default IconBoxComponent;