import React, { type FC, } from 'react';
// import { useAppSelector } from '../redux/hooks';
import { Navigate, useLocation } from 'react-router-dom';

interface PropType {
    component: React.FC;
}

const PrivateRoute: FC<PropType> = ({ component: Component }) => {

	const location = useLocation();
	const isLoggedIn: any = localStorage.getItem('isLoggedIn');
	/* eslint-disable */
    if(JSON.parse(isLoggedIn) !== true) {
        return <Navigate to='/login' state={{ from: location }}/>;
    } else {
        return <><Component /></>;
    }
};

export default PrivateRoute;