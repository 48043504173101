import React, { type FC, type InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { InputCompoInterface } from '../Modals/inputComponent';
import { Text } from '@chakra-ui/react';

const InputComponent: FC<InputCompoInterface & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>> = (props) => {
	const { onChange, placeholder, type, error, helperText, disabled, id, label } = props;
	const [field] = useField(props);
	return (
		<>
			<Text as="label" fontWeight={500} color='#000' lineHeight={['10px', '10px', '10px']} fontSize={['14px', '16px', '16px']} mb={['10px', '15px', '15px']} display={{ base: 'inline-block' }}>{label} </Text>
			<input
				{ ...field }
				type={ type }
				onChange={ onChange }
				placeholder={ placeholder }
				disabled={disabled}
				id= { id }
				className='form_input'
			/>
			{error &&	<p style={{ color:'red', fontSize:12 }}>{helperText}</p> }
		</>
	);
};
export default InputComponent;
