import React from 'react';
import { Box, List, ListItem, Text } from '@chakra-ui/react';
import MarqueeIcon from '../assets/images/marquee-icon.png';
import '../style/form.css';
import { marqueeListItems } from '../Forms/inputForm';
import Marquee from 'react-fast-marquee';

const MarqueeComponent = () => {

	return (
		<Marquee play={true} style={{ backgroundColor: '#1E1E1E' }} speed={150}  pauseOnClick={true}>
			<Box backgroundColor='#1E1E1E' className='marquee_wrapper'>
				<Box>
					<List spacing={3}>
						{marqueeListItems.map((item) => (
							<ListItem key={item}>
								<img src={MarqueeIcon} alt="icon" />
								<Text>{item}</Text>
							</ListItem>
						))}
					</List>
				</Box>
			</Box>
		</Marquee>
	);
};
export default MarqueeComponent;