import React, { type FC } from 'react';
import { ButtonCompoInterface } from '../Modals/buttonComponent';
import { Button } from '@chakra-ui/react';

const ButtonComponent: FC<ButtonCompoInterface> = ({ describedBy, type, btnName, onClick, className, disabled }) => {
	return (
		<>
			<Button
				aria-describedby={describedBy}
				type={type}
				onClick={onClick}
				className={className}
				disabled={disabled}
				border='1px'
				borderColor='#8F8F9D'
				px={['20px', '30px', '50px']}
				py={['10px', '15px', '20px']}
				bg='transparent'
				fontSize={['16px', '18px', '18px']}
				lineHeight={['25px', '27px', '27px']}
				color={'#000'}
				borderRadius={'0'}
			>
				{btnName}
			</Button>
		</>
	);
};
export default ButtonComponent;
