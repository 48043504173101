import React, { type FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import UserForm from '../Forms';
import PrivateRoute from './PrivateRoute';
import SuccessPage from '../pages/success-page';

const RouteProvider: FC = () => {
	return (
		<>
			<Routes>
				<Route
					path="login"
					element = {
						<Login />
					}
				/>
				<Route
					path="form"
					element = {
						<UserForm />
					}
				/>
				<Route
					path="success"
					element = {
						<SuccessPage />
					}
				/>
				<Route
					path=""
					element = {
						 <PrivateRoute component={Dashboard} />
					}
				/>
			</Routes>
		</>
	);
};

export default RouteProvider;