/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Box, Button, Checkbox, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import DataModal from './ModalComponent';
import ImagesSliderModal from './ImageSliderModal';
import { ViewIcon, CalendarIcon, MinusIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/react';
import { updatePresentation } from '../utils/api';

const useSortableData = (items: string[], config = null) => {
	const [sortConfig, setSortConfig] = useState<any>(config);
	const sortedItems = React.useMemo(() => {
		const sortableItems = [...items];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [items, sortConfig]);

	const requestSort = (key: string) => {
		let direction = 'ascending';
		if (
			sortConfig &&
			sortConfig.key === key &&
			sortConfig.direction === 'ascending'
		) {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	return { items: sortedItems, requestSort, sortConfig };
};
const DataTable = (props: any) => {
	const { list, loading } = props;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { items, sortConfig } = useSortableData(list);
	const [isOpen, setIsOpen] = useState(false);
	const [isimageSliderOpen, setIsImagesSliderOpen] = useState(false);

	const [rowDetail, setRowDetail] = useState({});
	const [files, setFiles] = useState<any>([{}]);
	const handleOpenModal = (rowDetail: object) => {
		setRowDetail(rowDetail);
		setIsOpen(true);
	};


	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const handleOpenDocument = (docArray: any[], sku: string) => {
		// console.log('doc type', docArray);
		const docData = [];
		for (let i = 0; i < docArray.length; i++) {
			if (docArray[i].type === 'doc') {
				const fileName = docArray[i].link.split('/').pop().split('#')[0].split('?')[0];;
				docArray[i].fileName = fileName;
				docArray[i].sku = sku;
				docData.push(docArray[i]);
			}
		}
		if (docData.length > 0) {
			setFiles(docData);
			setIsImagesSliderOpen(true);
		}
	};

	const handleOpenImageSlider = (imageArray: any[], sku: string) => {
		setFiles([]);
		const imageData = [];
		for (let i = 0; i < imageArray.length; i++) {
			if (imageArray[i].type === 'photo') {
				imageArray[i].sku = sku;
				imageData.push(imageArray[i]);
			}
		}
		if (imageData.length > 0) {
			setFiles(imageData);
			setIsImagesSliderOpen(true);
		}
	};
	const handleOpenVideoSlider = (videoArray: any[], sku: string) => {
		const videoData = [];
		for (let i = 0; i < videoArray.length; i++) {
			if (videoArray[i].type === 'video') {
				videoArray[i].sku = sku;
				videoData.push(videoArray[i]);
			}
		}
		if (videoData.length > 0) {
			setFiles(videoData);
			setIsImagesSliderOpen(true);
		}
	};

	const handleCloseImageSlider = () => {
		setIsImagesSliderOpen(false);
	};

	const handeUpdate = async(row: any) => {
		const data = {
			...row, ischecked: row?.presentation !== false && row?.presentation !== undefined ? false : true
		};
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const updateResult = await updatePresentation(data.id, { presentation: true });
	};

	const _getPresentation = (row: any) => {
		if (row.presentation === true) {
			return 'Done';
		} else {
			return 'Pending';
		}
	};

	const _getBoolean = (row: any) => {
		if (row.presentation === true) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<TableContainer>
			<Table >
				<Thead>
					<Tr bg={'#EBEBEB'}>
						<Th>
							<Button type="button" colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								Presentation
							</Button>
						</Th>
						<Th>
							<Button type="button" colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								SKU
							</Button>
						</Th>
						<Th > <Button
							type="button"
							colorScheme='transparent'
							color={'#000'}
							fontSize={'16px'}
							px={'0'}
						>
							Artist Name </Button>
						</Th>
						<Th >
							<Button
								type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}
							>
								Contact</Button>
						</Th>
						<Th >
							<Button
								type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}
							>
								Email</Button>
						</Th>
						<Th > <Button
							type="button"
							colorScheme='transparent'
							color={'#000'}
							fontSize={'16px'}
							px={'0'}
						>
							Role</Button>
						</Th>
						{/* <Th >
							<Button
								type="button"
								// onClick={() => requestSort('gender')}
								className={getClassNamesFor('name')}
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}
							>
								Gender</Button>
						</Th> */}
						<Th > <Button
							type="button"
							colorScheme='transparent'
							color={'#000'}
							fontSize={'16px'}
							px={'0'}
						>
							Location</Button>
						</Th>
						<Th>
							<Button type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								Doc
							</Button>
						</Th>
						<Th>
							<Button type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								Image
							</Button>
						</Th>
						<Th>
							<Button type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								Video
							</Button>
						</Th>
						<Th>
							<Button type="button"
								colorScheme='transparent'
								color={'#000'}
								fontSize={'16px'}
								px={'0'}>
								Info
							</Button>
						</Th>
					</Tr>
				</Thead>
				<Tbody className='table-body'>
					{(!loading && items.length > 0)&&
						items.map((row: any, index: any) => (
							<Tr key={index}>
								<Td fontSize={'16px'} color={'#000'} display='flex'>
									{row.presentation === false && (
										<Checkbox
											checked={_getBoolean(row)}
											defaultChecked={_getBoolean(row)}
											isDisabled={_getBoolean(row)}
											type="checkbox" onChange={() => { handeUpdate(row); }}>
										</Checkbox>
									)}
									<Box marginLeft={'6px'} marginTop={'2px'}>
										{
											_getPresentation(row)
										}
									</Box>
								</Td>
								<Td fontSize={'16px'} color={'#000'} >
									{row?.sku}
								</Td>
								<Td fontSize={'16px'} color={'#000'} >
									{row?.artistName}
								</Td>
								<Td fontSize={'16px'} color={'#000'} >
									{row?.phoneNumber}
								</Td>
								<Td className='normal-text' fontSize={'16px'} color={'#000'} >
									{row?.email}
								</Td>
								<Td fontSize={'16px'} color={'#000'} >
									{row?.artistRole}
								</Td>
								{/* <Td fontSize={'16px'} color={'#000'} >
									{row?.gender}
								</Td> */}
								<Td fontSize={'16px'} color={'#000'} >
									{row?.cityLocation}
								</Td>
								<>
									{row?.files && row?.files.filter((value: any) => value.type === 'doc').length > 0 ? (
										<Td fontSize={'16px'} color={'#000'}>
											<Button
												bg='transparent'
												borderRadius={'0'}
												onClick={() => handleOpenDocument(row?.files, row?.sku)}
												_hover={{ bg: 'transparent' }}
											>
												<CalendarIcon />
											</Button>
										</Td>
									) : (
										<Td fontSize={'16px'} color={'#000'}><MinusIcon marginLeft={'3'} /></Td>
									)}
								</>
								<>
									{row?.files && row?.files.filter((value: any) => value.type === 'photo').length > 0 ? (
										<Td fontSize={'16px'} color={'#000'}>
											<Button bg='transparent' borderRadius={'0'} onClick={() => handleOpenImageSlider(row?.files, row?.sku)} _hover={{ bg: 'transparent' }} >
												<Image width={'20px'} height={'20px'} objectFit= {'contain'} src={require('../assets/images/imageIcon.png')} alt={'Image'} />
											</Button>
										</Td>
									) : (
										<Td fontSize={'16px'} color={'#000'}><MinusIcon marginLeft={'3'} /></Td>
									)}
								</>
								<>
									{row?.files && row?.files.filter((value: any) => value.type === 'video').length > 0 ? (
										<Td fontSize={'16px'} color={'#000'}>
											<Button bg='transparent' borderRadius={'0'} onClick={() => handleOpenVideoSlider(row?.files, row?.sku)} _hover={{ bg: 'transparent' }} ><Image width={'20px'} height={'20px'} objectFit= {'contain'} src={require('../assets/images/videoIcon.png')} alt={'Video'} />
											</Button>
										</Td>
									) : (
										<Td fontSize={'16px'} color={'#000'} marginLeft={'18px'}><MinusIcon marginLeft={'3'} /></Td>
									)}
								</>
								<Td fontSize={'16px'} color={'#000'} >
									<Button bg='transparent' borderRadius={'0'} onClick={() => handleOpenModal(row)} _hover={{ bg: 'transparent' }} ><ViewIcon /></Button>
								</Td>
							</Tr>
						))
					}
				</Tbody>
			</Table>
			{(!loading && items.length === 0) && <div className='no-data-found'>No Data Found</div>}
			{(loading) && <div className='no-data-found'>Loading...</div>}
			<DataModal isOpen={isOpen} onClose={handleCloseModal} data={rowDetail} />
			<ImagesSliderModal isOpen={isimageSliderOpen} onClose={handleCloseImageSlider} files={files}></ImagesSliderModal>
		</TableContainer>

	);
};
export default DataTable;
