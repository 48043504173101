import React from 'react';
import './App.css';
import RouteProvider from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

function App() {
	const colors = {
		brand: {
		  900: '#1a365d',
		  800: '#153e75',
		  700: '#2a69ac',
		}
	  };
	  const fonts = {
		heading: 'Futura LT Pro Medium',
    	body: 'Futura LT Pro Light',
	  };
	  const theme = extendTheme({ colors, fonts });
	return (
		<ChakraProvider theme={theme}>
			<BrowserRouter>
		  		<RouteProvider />
	 		</BrowserRouter>
		</ChakraProvider>

	);
}

export default App;
