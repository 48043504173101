import React, { type FC } from 'react';
import { InputFeedbackInterface } from '../Modals/InputFeedbackInterface';

const InputFeedback: FC<InputFeedbackInterface> = ({
	error }) => {
	return (
		<>
			 {
				error ? <p style={{ color:'red', fontSize: 12 }}>{error}</p> : null
			}
		</>
	);
};
export default InputFeedback;
