import React  from 'react';
import { Link } from 'react-router-dom';
import { Box, Image, List, ListItem } from '@chakra-ui/react';
import siteLogo from '../assets/images/logo.png';
import '../style/header.css';

const HeaderComponent = () => {
	return (
		<Box className="header">
			<Box className='logo'>
				<Link to='https://clicbeat.com'>
					<Image
						src={siteLogo}
						width={'100%'}
						maxW={['260px', '280px', '310px']}
						alt='Logo'
					/>
				</Link>
			</Box>
			<Box className='nav-menu-main'>
				<Box className='main-menu left-part'>
					<List>
						<ListItem>
							<Link to='https://clicbeat.com/music/'>Music</Link>
						</ListItem>
						<ListItem>
							<Link to='https://clicbeat.com/entertainment/'>Entertainment</Link>
						</ListItem>
						<ListItem>
							<Link to='https://clicbeat.com/contact/'>Contact</Link>
						</ListItem>
					</List>
				</Box>
				<Box className='main-menu right-part'>
					<List>
						<ListItem>
							<Link to={`${window.location.origin}/form`}>artist sign-up</Link>
						</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	);
};
export default HeaderComponent;