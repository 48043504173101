import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import BannerImg from '../assets/images/banner-bg.png';
import '../style/form.css';

const BannerComponent = () => {

	return (
		<Box>
			<Flex flexWrap={'wrap'}>
				<Box className='banner_content_col'>
					<Box pt={['20px', '60px', '60px']} pb={['20px', '65px', '65px']}>
						<Heading as='h3' mb={3}>Artist Sign up</Heading>
						<Heading as='h1' mb={5}>Sign up to our platform</Heading>
						<Box pr={9}>
							<Text mb={8}>We offer access to a diverse roster of local and international DJs, skilled in providing music across a <Text as='span'>wide range of genres.</Text></Text>
							<Text>Whether for <Text as='span'>one-off events</Text> or <Text as='span'>long-term residencies</Text>, our DJs are equipped to deliver unforgettable auditory experiences, ensuring professional musical accompaniment for any occasion.</Text>
						</Box>
					</Box>
				</Box>
				<Box className='banner_img_col' style={{ textAlign: 'right' }}>
					<Box className="banner_img" style={{ width: '100%' }}>
						<img src={BannerImg} alt="banner" />
					</Box>
				</Box>
			</Flex>
		</Box>
	);
};
export default BannerComponent;