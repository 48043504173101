/* eslint-disable no-unused-vars */
import React, { type FC } from 'react';
import Select from 'react-select';

interface SelectComponentProps {
	value?: string,
	field?: any,
	form?: any,
	id?: string,
	label?: string,
	options: Array<object>,
}

const SelectComponent: FC<SelectComponentProps> = ({ field, label, form, options,  ...props }) => {

	const { name, value, onBlur } = field;

	const handleSelectChange = (selectedOption: any) => {
		const selectedValue = selectedOption ? selectedOption.value : '';

		form.setFieldValue(name, selectedValue);
	//   form.setFieldTouched(name, true);
	};

	return (
		<>
		    <div className='select-dropdown'>
				<label className='chakra-text'>{label}</label>
				<Select
					name={name}
					value={value ? value.value : ''}
					options={options}
					onChange={handleSelectChange}
					onBlur={onBlur}
					placeholder="Country"
					classNamePrefix="country_list"
					styles={{
						control: (provided) => ({
						  ...provided,
						  height: '50px',
						  border: 'solid #8F8F9D 1px',
						  borderRadius: '0'
						}),
					  }}
					  {...props}
				/>
				{form.touched[name] && form.errors[name] && (
					<p style={{ color: '#ff0000', fontWeight: 400, fontSize: '0.75rem' }}>{form.errors[name]}</p>
				)}
			</div>
		</>
	);
};

export default SelectComponent;