/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, type FC, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Formik, Field, Form } from 'formik';
import { Box, SimpleGrid, Text, Flex, Spinner, RadioGroup, HStack, Radio, Checkbox, Heading } from '@chakra-ui/react';
import { userInfoSchema } from './validation';
import { addData, db, insertPreFix, _returnId } from '../utils/firebase';
import { _getRefreshToken } from '../utils/api';
import { FormInputInterface } from '../Modals/FormInputInterface';
import { genderOption, initialValues, roleOption, djOptionsNew, musicianOption, MusicGenreOptionsNew, languageOptionNew } from './inputForm';
import HeaderComponent from '../Components/HeaderComponent';
import FooterComponent from '../Components/FooterComponent';
import InputComponent from '../Components/InputComponent';
import RadioButtonGroup from '../Components/RadioButtonGroup';
import ResponseModal from '../Components/ResponseModal';
import ButtonComponent from '../Components/ButtonComponent';
import uploadIcon from '../assets/images/upload-icon.svg';
import uploadimgIcon from '../assets/images/upload-img-icon.svg';
import deleteIcon from '../assets/images/delete-icon.svg';
import { useToast } from '@chakra-ui/react';
import '../style/form.css';
import { collection, onSnapshot, query } from 'firebase/firestore';
import SelectComponent from '../Components/SelectComponent';
import countryCodeOptions from './countryCode.json';
import InputFeedback from '../Components/InputFeedback';
import FormImgOne from '../assets/images/form-img.png';
import FormImgTwo from '../assets/images/form-img-two.png';
import BannerComponent from '../Components/BannerComponent';
import MarqueeComponent from '../Components/MarqueeComponents';
import IconBoxComponent from '../Components/IconBoxComponent';
import ClientLogoComponent from '../Components/ClientLogoComponent';
// import countryCode from './countryCode.json';
// interface RowItem {
// 	fileRef?: any,
// 	item?: string,
// 	name?: string,
// 	className?: string,
// }
// console.log('window scree', window.location);
const UserForm: FC = () => {
	const toast = useToast();
	const [images, setImages] = useState<any>([]);
	const [documents, setDocuments] = useState<any>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [id, setId] = useState<any>(0);
	const [lastId, setLastId] = useState(0);
	const [maxDoc, setMaxDoc] =useState(false);
	const [maxImg, setMaxImg] = useState(false);
	const [isSubmitting, setSubmitFlag] = useState(false);
	const [loader, setLoader] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [selectedLang, setSelectedLang] = useState<any>([]);
	const [selectedMusicGenre, setSelectedMusicGenre] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [djOther, setDjOther] = useState(false);
	const [musicOther, setMusicOther] = useState(false);
	const [langOther, setLangOther] = useState(false);
	// const fileRef = useRef(null);

	const onImageChange = (event: any, setFieldValue: any) => {

		const newImages = Array.from(event.target.files);
		if (newImages.length <= 5)
		{
			const uniqueImages = newImages.filter((newImage: any) => {
				return !images.some((images: any) => images.name === newImage.name);
			});
			setImages((prevImages: any) => [...prevImages, ...uniqueImages]);
			event.target.value = '';
			setMaxImg(false);
			setFieldValue('images', String((prevImages: any) => [...prevImages, ...uniqueImages]));
		} else {
			setMaxImg(true);
		}
	};

	const onDocChange = (event: any, setFieldValue: any) => {
		const newFiles = Array.from(event.target.files);
		if (newFiles.length <= 5 )
		{
			const uniqueFiles = newFiles.filter((newFile: any) => {
				return !documents.some((documents: any) => documents.name === newFile.name);
			  });
			setDocuments((prevFiles: any) => [...prevFiles, ...uniqueFiles]);
			event.target.value = '';
			setMaxDoc(false);
			setFieldValue('documents', String((prevFiles: any) => [...prevFiles, ...uniqueFiles]));
		} else {
			setMaxDoc(true);
		}
	};

	const _getLastId = () => {
		const q = query(collection(db, 'prefix'));
		onSnapshot(q, (snapshot) => {
			// console.log(snapshot)
			const list = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
			const lastEl: any = list.at(-1) || list[list.length - 1];
			// if (list.length > 0){
			// 	return	setLastId(list.length);
			// } else {
			setLastId(lastEl.count);
			// }
		});
	};

	useEffect(() => {
		_getLastId();
	}, []);

	const handleSubmit = async(values: FormInputInterface, actions: any) => {
		console.log('data submitted', values);
		try {

			const val = {
				realName: values.realName.toLowerCase(),
				artistName: values.artistName.toLowerCase(),
				gender: values.gender.toLowerCase(),
				nationality: values.nationality.toLowerCase(),
				cityLocation: values.cityLocation.toLowerCase(),
				phoneNumber: `${values.countryCode}${values.phoneNumber}`,
				whatsappNumber: `${values.whcountryCode}${values.whatsappNumber}`,
				email: values.email.toLowerCase(),
				artistRole: values.artistRole.toLowerCase() === 'Other' ? values.otherArtist.toLowerCase(): values.artistRole.toLowerCase(),
				soundCloud: values.artistRole === 'DJ' ? values.soundCloud.toLowerCase() : '',
				fee: values.fee,
				youTube: values.youTube.toLowerCase(),
				instagram:  values.instagram.toLowerCase(),
				instruments:  values.artistRole === 'DJ' ? djOther === true ? values.otherInstrument.toLowerCase() : '' : values.instruments,
				musicGenre: musicOther === true ?  values.otherMusicGenre.toLowerCase() : selectedMusicGenre.join(','),

				// musicGenre: values.artistRole === 'DJ' ? musicOther === true ?  values.otherMusicGenre.toLowerCase() : selectedItems.join(',') :
				// 	musicOther === true ?  values.otherMusicGenre.toLowerCase() : selectedMusicGenre.join(','),
				language: langOther === true ?  values.otherLanguages.toLowerCase() : selectedLang.join(','),

			};


			if (images.length > 0 && documents.length > 0){
				setLoader(true);
				setSubmitFlag(true);
				// _getLastId();
				const data = {
					...val,
					// createdTime: new Date(),
					presentation: false,
					// sku: _getSku(values.artistRole)
				};
				const id: any = await addData(data, images, documents);
				if (id === 'success') {
					// window.location.href = 'https://clicbeat.com/';
					// setIsOpen(true);
					actions.resetForm();
					setImages([]);
					setDocuments([]);
					setSelectedMusicGenre([]);
					setLoader(false);
					insertPreFix(lastId);
					const newUrl = `${window.location.origin}/success`;
					window.history.pushState({ path: newUrl }, '', newUrl);
					window.location.href = newUrl;
				} else {
					toast({
						title: 'error adding form ....',
						description: id.response,
						status: 'error',
						duration: 1500,
						isClosable: true,
					});
					setLoader(false);
					return;
				}
			} else {
				return;
			}
		} catch (err) {
			console.log(err);
			setSubmitFlag(true);
		} finally {
			setSubmitFlag(true);
		}
	};
	const handleCloseModal = () => {
		setIsOpen(false);
		console.log('id,', id);
		setSelectedItems([]);
		setSelectedLang([]);
		setSelectedMusicGenre([]);
	};

	const _doc = (doc: any, index: number, setFieldValue: any) => {
		return (
			<Flex key={index} alignItems={'center'} >
				<Box as={'span'} lineHeight={10} fontSize={16} fontWeight={500} color='#000'>{doc?.name}{' '}</Box>
				<button
					type='button'
					onClick={() => {
						const arr = documents.filter((item: any) => item !== doc);
						setFieldValue('documents', String(documents.filter((item: any) => item !== doc)));
						setDocuments(arr);
					}}
				>
					<ReactSVG src={deleteIcon} style={{ marginLeft : 15 }} />
				</button>
			</Flex>
		);
	};

	const _image =(imageSrc: any, index: number, setFieldValue: any) => {
		return (
			<Flex alignItems={'center'} key={index}>
				<Box as={'span'} lineHeight={10} fontSize={16} fontWeight={500} color='#000'>{imageSrc.name}</Box>
				<button
					type='button'
					onClick={() => {
						const arr = images.filter((item: any) => item !== imageSrc);
						setFieldValue('images', String(images.filter((item: any) => item !== imageSrc)));
						setImages(arr);
					}}
				>
					<ReactSVG src={deleteIcon} style={{ marginLeft : 15 }} />
				</button>
			</Flex>
		);
	};

	useEffect(() => {
		_getRefreshToken();
	}, []);


	useEffect(() => {
		const index =  _returnId(0);
		setId(index);
	}, []);

	// const handleChanges = (e: any, setFieldValue: any) => {
	// 	const { value, checked } = e.target;

	// 	if (checked) {
	// 		setFieldValue('instruments', value);
	// 	  setSelectedItems([...selectedItems, value]);
	// 	}
	// 	else {

	// 	  setSelectedItems(selectedItems.filter((item: any) => item !== value));
	// 	  console.log('selectedItems', selectedItems);

	// 		setFieldValue('instruments', String(selectedItems.filter((item: any) => item !== value)));

	// 	}
	// 	setDjOther(false);
	//   };
	  const handleChangeMusicGenre = (e: any, setFieldValue: any) => {
		const { value, checked } = e.target;

		if (checked) {
			setFieldValue('musicGenre', value);
			setSelectedMusicGenre([...selectedMusicGenre, value]);
		}
		else {
			setSelectedMusicGenre(selectedMusicGenre.filter((item: any) => item !== value));
			setFieldValue('musicGenre', String(selectedMusicGenre.filter((item: any) => item !== value)));

		}
		setMusicOther(false);
	  };
	  const handleChangeLang = (e: any, setFieldValue: any) => {
		const { value, checked } = e.target;

		if (checked) {
			setFieldValue('language', value);
			setSelectedLang([...selectedLang, value]);
		}
		else {
			setSelectedLang(selectedLang.filter((item: any) => item !== value));
			setFieldValue('language', String(selectedLang.filter((item: any) => item !== value)));

		}
		setLangOther(false);
	  };
	//   const handleDjOther = (setFieldValue: any) => {
	// 	setSelectedItems([]);
	// 	setDjOther(!djOther);
	// 	setFieldValue('instruments', 'Other');
	//   };
	  const handleMusicGenre = (setFieldValue: any) => {
		setSelectedMusicGenre([]);
		setMusicOther(!musicOther);
		setFieldValue('musicGenre', 'other');
	  };
	  const handleLangOther = (setFieldValue: any) => {
		setSelectedLang([]);
		setLangOther(!langOther);
		setFieldValue('language', 'other');
	  };
	  const getInstrumentLabel = (instrument: string) => {
		if (instrument === 'Band') {
			return <label> What languages can you/your vocalist(s) sing in?</label>;
		} else if (instrument === 'Singer') {
			return <label> What languages can you sing in?</label>;
		}
	  };

	return (
		<>
			<HeaderComponent />
			<BannerComponent />
			<MarqueeComponent />
			<IconBoxComponent />
			<ClientLogoComponent />
			{/* form section */}
			<Box>
				<Box backgroundColor='#F4EFE9' py={'44px'} className='form_header_wrapper'>
					<Heading as='h2' textAlign='center'>Artist Sign up</Heading>
				</Box>
				<Box className='registration_form' overflow={'hidden'}>
					<Formik
						initialValues={initialValues}
						validationSchema={userInfoSchema}
						onSubmit={handleSubmit}
					>
						{({ values, errors, handleChange, handleBlur, touched, setFieldValue }) => {

							return (
								<Form>
									<Box className='form_row borber_bottom'>
										<Box className='form_img_col'>
											<Heading as='h3'><Text fontFamily={'Optima LT Pro'} as='span' ml={['2', '8', '0']}>Tell us about yourself</Text></Heading>
											<img src={FormImgOne} alt="Form image" className='form-image-1' />
										</Box>
										<Box className='form_data_col'>
											<Box mb={'15px'}>
												<InputComponent
													id='realName'
													label={'What is your real name? *'}
													name='realName'
													value={values.realName}
													placeholder={'Real name'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.realName ?? false) && errors.realName != null)}
													helperText={(touched.realName ?? false) && errors.realName}
													required
												/>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													id='artistName'
													label={'What name do you perform under as an artist? *'}
													name='artistName'
													value={values.artistName}
													placeholder={'Artist/Act name'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.artistName ?? false) && errors.artistName != null)}
													helperText={(touched.artistName ?? false) && errors.artistName}
													required
												/>
											</Box>
											<Box className='gender_radio_btn' mb={'15px'}>
												<RadioButtonGroup
													id='gender'
													label='What is your gender? *'
													value={values.gender}
													error={errors.gender}
													touched={touched.gender}
												>
													<RadioGroup id='gender' name='gender' value={values.gender}>
														<HStack spacing={3}>
															{genderOption.map((item, index) => (
																<Radio
																	key={`gender_${index}`}
																	value={item}
																	onChange={handleChange}
																>{item}
																</Radio>
															))}
														</HStack>
													</RadioGroup>
												</RadioButtonGroup>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													id='nationality'
													label={'What is your nationality? *'}
													name='nationality'
													value={values.nationality}
													placeholder={'Your nationality'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.nationality ?? false) && errors.nationality != null)}
													helperText={(touched.nationality ?? false) && errors.nationality}
													required
												/>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													id='cityLocation'
													label={'In which city or location are you currently based?*'}
													name='cityLocation'
													value={values.cityLocation}
													placeholder={'Location'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.cityLocation ?? false) && errors.cityLocation != null)}
													helperText={(touched.cityLocation ?? false) && errors.cityLocation}
													required
												/>
											</Box>
											<Box mb={'15px'} display="flex" flexWrap={'wrap'} >
												<Box className='main_select_dropdown'>
													<Field  label={'Country code'} name="countryCode" component={SelectComponent} options={countryCodeOptions} />
													{/* <SelectComponent  data={countryCode} setValue={setCountryCode} /> */}
												</Box>
												<Box className='phone_number'>
													<InputComponent
														id='phoneNumber'
														label={'Can you please provide your phone number? *'}
														// label={'What is your phone number? *'}
														name='phoneNumber'
														value={values.phoneNumber}
														placeholder={'Phone number'}
														type='text'
														variant='standard'
														onBlur={handleBlur}
														onChange={handleChange}
														error={!!((touched.phoneNumber ?? false) && errors.phoneNumber != null)}
														helperText={(touched.phoneNumber ?? false) && errors.phoneNumber}
														required
													/>
												</Box>
											</Box>
											<Box mb={'15px'} display="flex" flexWrap={'wrap'}>
												<Box className='main_select_dropdown'>
													<Field  label={'Country code'} name="whcountryCode" component={SelectComponent} options={countryCodeOptions} />
													{/* <SelectComponent  data={countryCode} setValue={setCountryCode} /> */}
												</Box>
												<Box className='phone_number'>
													<InputComponent
														id='whatsappNumber'
														label={'What is your WhatsApp number? (if different from your phone number)'}
														name='whatsappNumber'
														value={values.whatsappNumber}
														placeholder={'Whatsapp number'}
														type='text'
														variant='standard'
														onBlur={handleBlur}
														onChange={handleChange}
														error={!!((touched.whatsappNumber ?? false) && errors.whatsappNumber != null)}
														helperText={(touched.whatsappNumber ?? false) && errors.whatsappNumber}
													/>
												</Box>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													id='email'
													label={'What is your email address? *'}
													name='email'
													value={values.email}
													placeholder={'Your email'}
													type='email'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.email ?? false) && errors.email != null)}
													helperText={(touched.email ?? false) && errors.email}
												/>
											</Box>
											{/* <Box mb={'15px'}>
												<InputComponent
													id='fee'
													label={'What is your expected fee per performance or act in AED? *'}
													name='fee'
													value={values.fee}
													placeholder={'Expected fee'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.fee ?? false) && errors.fee != null)}
													helperText={(touched.fee ?? false) && errors.fee}
													required
												/>
											</Box> */}
										</Box>
									</Box>
									<Box className='form_row'>
										<Box className='form_img_col'>
											<Heading as='h3'><Text fontFamily={'Optima LT Pro'} as='span' ml={['2', '8', '0']}>Show us your art</Text></Heading>
											<img src={FormImgTwo} alt="Form image" />
										</Box>
										<Box className='form_data_col'>
											<Box mb={'15px'}>
												<RadioButtonGroup
													id='artistRole'
													label='What is your artistic specialty?'
													value={values.artistRole}
													error={errors.artistRole}
													touched={touched.artistRole}
												>
													<RadioGroup id='artistRole' name='artistRole' value={values.artistRole}>
														<HStack spacing={3}>
															{roleOption.map((item, index) => (
																<Radio
																	key={`artistRole_${index}`}
																	value={item}
																	onChange={(event: React.ChangeEvent<any>) => {
																		values.instruments = '';
																		values.musicGenre = '';
																		values.otherMusicGenre = '';
																		values.language = '';
																		values.otherLanguages = '';
																		setMusicOther(false);
																		setLangOther(false);
																		setSelectedMusicGenre([]);
																		setSelectedLang([]);
																		handleChange(event);
																	}}
																>
																	{item}
																</Radio>
															))}
														</HStack>
													</RadioGroup>
												</RadioButtonGroup>
												<Box mt={'16px'}>
													{values.artistRole === 'Musician' ? (
														<RadioButtonGroup
															id='instruments'
															label='Which instrument(s) do you play?'
															value={values.instruments}
															error={errors.instruments}
															touched={touched.instruments}
														>
															<RadioGroup id='instruments' name='instruments' value={values.instruments}>
																<HStack spacing={3}>
																	{musicianOption.map((item, index) => (
																		<Radio
																			key={`instruments_${index}`}
																			value={item}
																			onChange={handleChange}
																		>{item}
																		</Radio>
																	))}
																</HStack>
															</RadioGroup>
														</RadioButtonGroup>
													) : values.artistRole === 'DJ' ? (
														<Box>
															<label>What music genres do you have experience performing?</label>
															<Flex sx={{ flexWrap: 'wrap' }}>
																{djOptionsNew.map((checkbox) => (
																	<div key={checkbox.id} className='checkbox from-checkbox'>
																		<Checkbox
																			value={checkbox.label}
																			id='musicGenre'
																			isChecked={selectedMusicGenre.includes(checkbox.label) || false}
																			onChange={(e) => {
																				handleChangeMusicGenre(e, setFieldValue);
																			}}
																		>
																			{checkbox.label}
																		</Checkbox>
																	</div>
																))}

																<div className='checkbox from-checkbox'>


																	<Checkbox
																		value={'Other'}
																		id='musicGenre'
																		isChecked={musicOther}
																		onChange={() =>
																			handleMusicGenre( setFieldValue)
																		}>
																			Other
																	</Checkbox>
																</div>
																{touched.musicGenre && <InputFeedback error={errors.musicGenre} />}
															</Flex>
															{/* <Flex sx={{ flexWrap: 'wrap' }}>
																{djOptionsNew.map((checkbox) => (
																	<div key={checkbox.id} className='checkbox from-checkbox'>
																		<Checkbox
																			value={checkbox.label}
																			id='instruments'
																			isChecked={selectedItems.includes(checkbox.label) || false}
																			onChange={(e) => {handleChanges(e, setFieldValue);}}>
																			{checkbox.label}
																		</Checkbox>
																	</div>
																))}
																<div className='checkbox from-checkbox'>
																	<Checkbox
																		value={'Other'}
																		id='musicGenre'
																		isChecked={musicOther}
																		onChange={() =>
																			handleMusicGenre( setFieldValue)
																		}>
																	Other
																	</Checkbox>
																</div>
																{touched.instruments && <InputFeedback error={errors.instruments} />}
															</Flex> */}
														</Box>
													) : null}
													{values.artistRole === 'DJ' && musicOther === true  && (
														<Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
															<InputComponent
																id='otherMusicGenre'
																label={'Please specify genre*'}
																name='otherMusicGenre'
																value={values.otherMusicGenre}
																placeholder={'Music genre'}
																type='text'
																variant='standard'
																onBlur={handleBlur}
																onChange={handleChange}
																error={!!((touched.otherMusicGenre ?? false) && errors.otherMusicGenre != null)}
																helperText={(touched.otherMusicGenre ?? false) && errors.otherMusicGenre}
															/>
														</Box>

													)}
													{values.artistRole === 'DJ' && djOther === true  && (
														<div className='instrument-field'>
															<InputComponent
																id='otherInstrument'
																label={'Please specify instrument*'}
																name='otherInstrument'
																value={values.otherInstrument}
																placeholder={'instrument'}
																type='text'
																variant='standard'
																onBlur={handleBlur}
																onChange={handleChange}
																error={!!((touched.otherInstrument ?? false) && errors.otherInstrument != null)}
																helperText={(touched.otherInstrument ?? false) && errors.otherInstrument}
															/>
														</div>
													)}
													{values.artistRole === 'Musician' && values.instruments === 'Other'  && (
														<div className='instrument-field'>
															<InputComponent
																id='otherInstrument'
																label={'Please specify instrument*'}
																name='otherInstrument'
																value={values.otherInstrument}
																placeholder={'instrument'}
																type='text'
																variant='standard'
																onBlur={handleBlur}
																onChange={handleChange}
																error={!!((touched.otherInstrument ?? false) && errors.otherInstrument != null)}
																helperText={(touched.otherInstrument ?? false) && errors.otherInstrument}
															/>
														</div>

													)}
													{values.artistRole === 'Musician' && (values.instruments === 'Band'
										|| values.instruments === 'Singer')?  <SimpleGrid columns={1} spacing={['0', '16px']} mb={['15px', '0', '0']} pt={['0', '20px', '10px']}>
															<Box mt={'5px'}>
																<>
																	<Box>
																		<label>What music genres do you have experience performing?</label>
																		<Flex sx={{ flexWrap: 'wrap' }}>
																			{MusicGenreOptionsNew.map((checkbox) => (
																				<div key={checkbox.id} className='checkbox from-checkbox'>
																					<Checkbox
																						value={checkbox.label}
																						id='musicGenre'
																						isChecked={selectedMusicGenre.includes(checkbox.label) || false}
																						onChange={(e) => {
																							handleChangeMusicGenre(e, setFieldValue);
																						}}
																					>
																						{checkbox.label}
																					</Checkbox>
																				</div>
																			))}

																			<div className='checkbox from-checkbox'>


																				<Checkbox
																					value={'Other'}
																					id='musicGenre'
																					isChecked={musicOther}
																					onChange={() =>
																						handleMusicGenre( setFieldValue)
																					}>
																						Other
																				</Checkbox>
																			</div>
																			{touched.musicGenre && <InputFeedback error={errors.musicGenre} />}
																		</Flex>
																	</Box>
																	{values.artistRole === 'Musician' && (values.instruments === 'Band'
																		|| values.instruments === 'Singer')
																		&&  musicOther === true  && (
																		<Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
																			<InputComponent
																				id='otherMusicGenre'
																				label={'Please specify genre*'}
																				name='otherMusicGenre'
																				value={values.otherMusicGenre}
																				placeholder={'Music genre'}
																				type='text'
																				variant='standard'
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={!!((touched.otherMusicGenre ?? false) && errors.otherMusicGenre != null)}
																				helperText={(touched.otherMusicGenre ?? false) && errors.otherMusicGenre}
																			/>
																		</Box>

																	)}
																	<Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
																		<Box>
																			{getInstrumentLabel(values.instruments)}

																			<Flex sx={{ flexWrap: 'wrap' }}>



																				{languageOptionNew.map((checkbox) => (
																					<div key={checkbox.id} className='checkbox from-checkbox'>
																						<Checkbox
																							value={checkbox.label}
																							id='language'
																							isChecked={selectedLang.includes(checkbox.label) || false}
																							onChange={(e) => {handleChangeLang(e, setFieldValue);}}>
																							{checkbox.label}
																						</Checkbox>
																					</div>
																				))}
																				<div className='checkbox from-checkbox'>
																					<Checkbox
																						value={'Other'}
																						id='instruments'
																						isChecked={langOther}
																						onChange={() => {handleLangOther(setFieldValue);}}>
																							Other
																					</Checkbox>
																				</div>
																			</Flex>
																			{touched.language && <InputFeedback error={errors.language} />}
																		</Box>
																	</Box>
																	{values.artistRole === 'Musician' && (values.instruments === 'Band'
																		|| values.instruments === 'Singer')
																		&& langOther === true && (
																		<Box sx={{ marginTop: '15px' }}>
																			<InputComponent
																				id='otherLanguages'
																				label={'Please specify language*'}
																				name='otherLanguages'
																				value={values.otherLanguages}
																				placeholder={'language'}
																				type='text'
																				variant='standard'
																				onBlur={handleBlur}
																				onChange={handleChange}
																				error={!!((touched.otherLanguages ?? false) && errors.otherLanguages != null)}
																				helperText={(touched.otherLanguages ?? false) && errors.otherLanguages}
																			/>
																		</Box>
																	)}
																</>


															</Box>
														</SimpleGrid> : null
													}
													{values.artistRole === 'Other' && (
														<SimpleGrid columns={1} spacing={['0', '2.75rem']} pt={'16px'}>
															<Box mb={['15px', '0', '0']}>
																<InputComponent
																	id='otherArtist'
																	label={'Please specify Artist role*'}
																	name='otherArtist'
																	value={values.otherArtist}
																	placeholder={'Artist Role'}
																	type='text'
																	variant='standard'
																	onBlur={handleBlur}
																	onChange={handleChange}
																	error={!!((touched.otherArtist ?? false) && errors.otherArtist != null)}
																	helperText={(touched.otherArtist ?? false) && errors.otherArtist}
																	required
																/>
															</Box>
														</SimpleGrid>
													)}

													{values.artistRole === 'DJ' && (
														<SimpleGrid columns={1} spacing={['0', '2.75rem']} pt={'16px'}>
															<Box>
																<InputComponent
																	id='soundCloud'
																	label={'Could you please provide the link for SoundCloud or MixCloud account? *'}
																	name='soundCloud'
																	value={values.soundCloud}
																	placeholder={'soundcloud.com/username'}
																	type='text'
																	variant='standard'
																	onBlur={handleBlur}
																	onChange={handleChange}
																	error={!!((touched.soundCloud ?? false) && errors.soundCloud != null)}
																	helperText={(touched.soundCloud ?? false) && errors.soundCloud}
																	required
																/>
															</Box>
														</SimpleGrid>
													)}
												</Box>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													id='youTube'
													label={'Can you please share a link to your YouTube channel?'}
													name='youTube'
													value={values.youTube}
													placeholder={'www.youtube.com/user/username'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.youTube ?? false) && errors.youTube != null)}
													helperText={(touched.youTube ?? false) && errors.youTube}
													required
												/>
											</Box>
											<Box mb={'15px'}>
												<InputComponent
													label={'Could you please provide your Instagram handle? *'}
													id='instagram'
													name='instagram'
													value={values.instagram}
													placeholder={'www.instagram.com/username'}
													type='text'
													variant='standard'
													onBlur={handleBlur}
													onChange={handleChange}
													error={!!((touched.instagram ?? false) && errors.instagram != null)}
													helperText={(touched.instagram ?? false) && errors.instagram}
													required
												/>
											</Box>
											<Box mb={'15px'}>
												<Text as="label" fontWeight={500} color='#000' lineHeight={['10px', '10px', '10px']} fontSize={['14px', '16px', '16px']} mb='15px' mt='10px' display={{ base: 'block' }}>{'Please share your Electronic Press Kit (EPK) or other professional presentation. *'}</Text>
												<label htmlFor="upload_file" className="upload_file_wrap">
													<ReactSVG src={uploadIcon} /> Upload
												</label>
												<Box as='span' fontSize={16} color={'#000'} lineHeight={'10px'} fontWeight={400} ml={'10px'}>No  files selected.</Box>
												{
													maxDoc && <Box as='span' fontSize={16} color={'#FF0000'} lineHeight={'10px'} fontWeight={400} ml={'10px'}>Please select only 5 documents</Box>
												}
												<input
													type='file'
													accept='.pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png'
													multiple
													name='documents'
													onChange={(e) => {
														onDocChange(e, setFieldValue);
													}}
													className='filetype'
													hidden
													id="upload_file"
												/>
												{
													documents.map((doc: any, index: any) => {
														return (
															<div
																key={index}
																style={{
																	overflow: 'hidden',
																}}>
																<>
																	{
																		_doc(doc, index, setFieldValue)
																	}
																</>
															</div>
														);
													})
												}
												{errors.documents && touched.documents && <p style={{ color: 'red', fontWeight: 400, fontSize: '0.75rem' }}> {errors.documents}</p>}
											</Box>
											<Box mb={'15px'} >
												<Text as="label" fontWeight={500} color='#000' lineHeight={['10px', '10px', '10px']} fontSize={['14px', '16px', '16px']} mb='15px' display={{ base: 'block' }}>{'Please provide some photos and videos showcasing your performances. *'}</Text>
												<label htmlFor="upload_img" className="upload_file_wrap">
													<ReactSVG src={uploadimgIcon} /> Upload
												</label>
												<Box as='span' fontSize={16} color={'#000'} lineHeight={'10px'} fontWeight={400} ml={'10px'}>No  files selected.</Box>
												{
													maxImg && <Box as='span' fontSize={16} color={'#FF0000'} lineHeight={'10px'} fontWeight={400} ml={'10px'}>Please select only 5 image/video</Box>
												}
												<input
													type='file'
													accept='audio/*,video/*,image/*'
													multiple
													onChange={(e) => {
														onImageChange(e, setFieldValue);
													}}
													className='filetype'
													hidden
													id="upload_img"
												/>
												{
													images.map((imageSrc: any, index: any) => {
														return (
															<div
																key={index}
																style={{
																	overflow: 'hidden',
																}}>
																<>
																	{
																		_image(imageSrc, index, setFieldValue)
																	}
																</>
															</div>
														);
													})
												}

												{errors.images &&   touched.images && <p style={{ color: 'red', fontWeight: 400, fontSize: '0.75rem' }}>{errors.images}</p>}
											</Box>
											<Box mb={['15px', '0', '0']}
												sx={{ display: 'flex', flexWrap: 'wrap' }}>

												<div className='checkbox from-checkbox'>
													<Checkbox
														value={'termsAndConditions'}
														name='termsAndConditions'
														isChecked={values.termsAndConditions}
														onChange={handleChange}
														id='termsAndConditions'>
													I agree to grant Clicbeat permission to use my submitted content on their social media channels.
													</Checkbox></div>
												{errors.termsAndConditions && touched.termsAndConditions ? (
													<p style={{ color: 'red', fontWeight: 400, fontSize: '0.75rem' }}>
														{errors.termsAndConditions}
													</p>): ''}
											</Box>
											<Box justifyContent={['flex-start', 'flex-end']} display={'flex'} mt={'35px'}>
												<ButtonComponent disabled={isSubmitting} type='submit' btnName='Submit' variant='contained' />
											</Box>
										</Box>
									</Box>
								</Form>
							);
						}}
					</Formik>
					{loader && (
						<Box sx={{
							position: 'fixed',
							top: '0',
							left: '0',
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backdropFilter: 'brightness(70%)',
							zIndex: 9999,
						}}>
							<Spinner  thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='blue.500'
								size='xl' />
						</Box>
					)}
					<ResponseModal isOpen={isOpen} onClose={handleCloseModal} />
				</Box>
			</Box>
			{/* form section ends */}
			<FooterComponent />
		</>
	);
};

export default UserForm;

