import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Button,
	Box,
	SimpleGrid,
	HStack,
	Radio,
	RadioGroup,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';
import RadioButtonGroup from './RadioButtonGroup';
// import RadioButton from './RadioButton';
import InputComponent from './InputComponent';
import ButtonComponent from './ButtonComponent';

interface FitterDialogProps {
    title: string;
    isOpen: boolean;
    onClose: any;
	getData: any;
	filterData: any;
}
const FitterDialog = ({
	//  title,
	isOpen,
	onClose,
	getData,
	filterData
}: FitterDialogProps) => {
	console.log(filterData.gender);
	const _getGenderNew = ( gender: string) => {
		switch (gender) {
			case 'male':
				return 'Male';
			case 'female':
				return 'Female';
			case 'not-shared':
				return 'Choose not to share';
			default:
				return '';
		}
	};
	const initialValues = {
		cityLocation: filterData.cityLocation || '',
		gender: _getGenderNew(filterData.gender) || '',
		presentation: filterData.presentation || '',
		artistName: filterData.artistName || '',
		artistRole: filterData.artistRole || ''
	};

	const _getGender = ( gender: string) => {
		switch (gender) {
			case 'Male':
				return 'male';
			case 'Female':
				return 'female';
			case 'Choose not to share':
				return 'not-shared';
			default:
				return '';
		}
	};
	const handleSubmit = (val: any)  => {
		const newVal = {
			artistName:val?.artistName,
			artistRole:val?.artistRole,
			cityLocation:val?.cityLocation,
			gender:_getGender(val.gender),
			presentation:val?.presentation,
		};
		const data = { ...newVal };
		getData(data);
		onClose();
	};

	const presentation = [
		{
			id: 2,
			value : 'Done'
		},
		{
			id: 3,
			value : 'Pending'
		},
	];
	// const roleOptions = [
	// 	'DJ', 'Musician', 'Dancer', 'Magician/illusioninst', 'Fire dancer', 'Balley dancer', 'other'
	// ];
	const genderOptions = [
		// {
		// 	id:1,
		// 	value:'All'
		// },
		{
			id:2,
			value:'Male'
		},
		{
			id:3,
			value:'Female'
		},
		{
			id:4,
			value:'Choose not to share'
		},

	];

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'5xl'} >
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Fitters</ModalHeader>
				<Button onClick={onClose} position={'absolute'} right={'-10px'} top={'-10px'} color={'#fff'} bg={'#000000'} width={'40px'} height={'40px'} borderRadius={'50%'} _hover={{ bg: '#000000' }}><CloseIcon /></Button>
				<ModalBody>
					<>
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmit}
						>
							{({ values,  handleChange, handleBlur }) => (
								<Form>
									<SimpleGrid columns={{ base: 1, md: 2 }} spacing={['0', '1.5rem']}>
										<Box mb={['15px', '0', '0']}>
											<InputComponent
												id='artistName'
												label={'Name'}
												name='artistName'
												value={values.artistName}
												placeholder={'Search artist name/email here...'}
												type='text'
												variant='standard'
												onBlur={handleBlur}
												onChange={handleChange}
											/>
										</Box>
										<Box mb={['15px', '0', '0']}>
											<InputComponent
												id='cityLocation'
												label={'Nationality / City location'}
												name='cityLocation'
												value={values.cityLocation}
												placeholder={'Search city here...'}
												type='text'
												variant='standard'
												onBlur={handleBlur}
												onChange={handleChange}
											/>
										</Box>
									</SimpleGrid>

									<SimpleGrid columns={{ base: 1, md: 2 }} spacing={['0', '1.5rem']} pt={['20px']}>
										<Box mb={['15px', '0', '0']}>
											<RadioButtonGroup
												id='gender'
												label='Gender'
												value={values.gender}
											>
												<RadioGroup id='gender' name='gender' value={values.gender}>
													<HStack spacing={3}>
														{genderOptions.map((item, index) => (
															<Radio
																key={`gender_${index}`}
																value={item.value}
																onChange={handleChange}
															>{item.value}
															</Radio>
														))}
													</HStack>
												</RadioGroup>
											</RadioButtonGroup>
										</Box>
										<Box mb={['15px', '0', '0']}>
											<RadioButtonGroup
												id='presentation'
												label='Presentation?'
												value={values.presentation}
											>
												<RadioGroup id='presentation' name='presentation' value={values.presentation}>
													<HStack spacing={3}>
														{presentation.map((item, index) => (
															<Radio
																key={`presentation_${index}`}
																value={item.value}
																onChange={handleChange}
															>{item.value}
															</Radio>
														))}
													</HStack>
												</RadioGroup>
											</RadioButtonGroup>
										</Box>
									</SimpleGrid>

									<SimpleGrid
										columns={{ base: 1, md: 1 }}
										spacing={['0', '2.75rem']}
										pt={['0', '20px', '30px']}
									>
										<Box
											justifyContent={['flex-start', 'flex-end']}
											display={'flex'}
										>
											<ButtonComponent type='submit' btnName='Search' variant='contained' />
										</Box>
			 					 	</SimpleGrid>
								</Form>
							)}
						</Formik>
					</>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default FitterDialog;