/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { auth } from '../../utils/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import DataTable from '../../Components/DataTable';
import '../../style/dashboard.css';
import { Box, Flex, IconButton, Input, useToast, Checkbox, InputGroup, InputLeftElement, Image, Text } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { ChevronLeftIcon, ChevronRightIcon, Search2Icon } from '@chakra-ui/icons';
import logoutIcon from '../../assets/images/exit-icon.svg';
import filterIcon from '../../assets/images/filter.svg';
import refreshIcon from '../../assets/images/refresh.svg';
import FitterDialog from '../../Components/FitterDailog';
// import { roleOption } from '../../Forms/inputForm';
import siteLogo from '../../assets/images/logo.png';
import CONSTANT from '../../Constant';

const pageLimit = 20;
// let timeoutInfo: any = null;
const Dashboard = () => {
	const toast = useToast();
	const [searchResults, setSearchResults] = useState<any>([]);
	const roleOptions = [
		{ id: 1, label: 'DJ' },
		{ id: 2, label: 'Musician' },
		{ id: 3, label: 'Dancer' },
		{ id: 4, label: 'Magician' },
		{ id: 5, label: 'Fire dancer' },
		{ id: 6, label: 'Belly dancer' },
		{ id: 7, label: 'Choreographer' },
		{ id: 8, label: 'Host/MC' },
		// { id: 7, label: 'other' }
	];
	const [selectedItems, setSelectedItems] = useState<any>([]);
	// const [checked, setChecked] = React.useState(false);
	const [isOpen, setIsOpen] = useState(false);
	// postgres data
	// const [lists, setLists] = useState([]);
	// const [loading, setLoading] = useState(tue);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [count, setTotalCount] = useState(0);
	const [filter, setFilter] = useState({});
	const [where, setWhere] = useState<any>({
		page: 1
	});

	const handleSearch = (event: any) => {
		setPage(1);
		setSearch(event.target.value);
		setWhere({
			...where,
			page: 1,
			search: event.target.value
		});
	};

	const nextPage = () => {
		setPage((prevPage) => prevPage + 1);
		setWhere({
			...where,
			page: page + 1
		});
	};

	const prevPage = () => {
		if (page > 1) {
			setPage((prevPage) => prevPage - 1);
			setWhere({
				...where,
				page: page - 1
			});
		}
	};

	const navigate = useNavigate();

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const resetFilter = () => {
		setFilter({});
		setPage(1);
		setSelectedItems([]);
		setWhere({
			search: null,
			page: 1,
			selectedItems: [],
		});
		// fetchArtists({
		// 	selectedItems: [],
		// });
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;

		let items = [...selectedItems];
		if (checked) {
			items = [...items, value];
		  setSelectedItems([...selectedItems, value]);
		}
		else {
			items = items.filter((item: any) => item !== value);
		}
		setSelectedItems([...items]);
		setPage(1);
		setWhere({
			...filter,
			page: 1,
			search: null,
			selectedItems: items
		});
	};

	const handleLogout = () => {
		signOut(auth).then(async() => {
			toast({
				title: 'Logout...',
				description: 'Sign-out successful.',
				status: 'success',
				duration: 1500,
				isClosable: true,
			});
			localStorage.setItem('isLoggedIn', 'false');
			navigate('/');
		}).catch((error) => {
			console.log(error);
		});
	};

	const handleFilterData = async(data: any) => {
		setSearch('');
		setFilter(data);
		setWhere({
			...data,
			selectedItems,
			page: 1,
			search: null,
		});
	};

	const fetchArtists = async(params: any) => {

		setLoading(true);
		try {
			const response = await fetch(
				`${CONSTANT.API_URL}/artists?search=${params.search || ''}&page=${params.page}&pageLimit=${pageLimit}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'email': auth.currentUser?.email || '',
					},
					body: JSON.stringify(params || {})
				});
			const result = await response.json();
			// const data = await response.json();
			// return;
			setSearchResults(result.data.artists);
			setTotalCount(result.data.totalCount);
			//   setLists(result.data.artists);
      		// setSearchResults(result.data.artists);
		} catch (error) {
		  console.error('Error fetching artists:', error);
		  toast({
				title: 'Unauthorized',
				description: 'You are not authorized person to view.',
				status: 'error',
				duration: 1500,
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	  };

	useEffect(() => {
		console.log('called once');
		auth.onAuthStateChanged((user) => {
			if (user) {
				fetchArtists(where);
			}
		});

	}, [where]);

	// useEffect(() => {
	// 	auth.onAuthStateChanged((user) => {
	// 		if (user) {
	// 			fetchArtists({
	// 				selectedItems
	// 			});
	// 		}
	// 	});
	// }, [where]);


	// useEffect(() => {
	// 	setFilter({});

	// 	auth.onAuthStateChanged((user) => {
	// 		if (user) {
	// 			fetchArtists({
	// 				selectedItems
	// 			});
	// 		}
	// 	});
	// }, [search]);


	return (
		<Box>
			<Flex className='dashboard_header'>
				<Box className='logo'>
					<Link to='https://clicbeat.com'>
						<Image
							src={siteLogo}
							width={'100%'}
							maxW={['220px', '240px', '260px']}
							alt='Logo'
						/>
					</Link>
				</Box>
				<Box className='search'>
					<InputGroup border={1} borderColor={'#000'} borderStyle={'solid'} borderRadius={0} _focusVisible={{ borderColor: '#000' }} _hover={{ borderColor: '#000' }}>
						<InputLeftElement pointerEvents='none'>
							<Search2Icon color='gray.300' />
						</InputLeftElement>
						<Input placeholder='Search by artist role here....'
							onChange={handleSearch} value={search} border={'none'} _focusVisible={{ border: 'none' }}
						/>
					</InputGroup>
				</Box>
				<Box className='header_icons'>
					<Flex alignItems={'center'} justifyContent={'space-between'}>
						<Box className='icon_list' onClick={() => resetFilter()}>
							<ReactSVG src={refreshIcon} className='action_icon refresh_icon' />
							<Text>Refresh</Text>
						</Box>
						<Box className='icon_list' onClick={handleOpenModal}>
							<ReactSVG src={filterIcon} className='action_icon filter_icon' />
							<Text>Filter</Text>
						</Box>
						<Box className='icon_list' onClick={handleLogout}>
							<ReactSVG src={logoutIcon} className='action_icon logout_icon' />
							<Text>Logout</Text>
						</Box>
					</Flex>
				</Box>
			</Flex>
			<Flex className='filter_checkbox' alignItems={'center'} justifyContent={'space-between'} px={'20px'} py={'20px'}>
				<Box className="filter_list">
					{/* <div className='checkbox'> */}
					{/* <input
							type="checkbox"
							checked={allSelected}
							onChange={handleSelectAll}
						/> */}
					{/* <Checkbox
							type="checkbox"
							checked={allSelected}
							onChange={handleSelectAll}>
						All
						</Checkbox> */}
					{/* </div> */}

					{roleOptions.map((checkbox) => (
						<div key={checkbox.id} className='checkbox'>
							{/* <input
								type="checkbox"
								value={checkbox.label}
								checked={selectedItems.includes(checkbox.label)}
								onChange={handleChange}
							/> */}
							<div>
								{selectedItems.includes(checkbox.label).toString()}
							</div>
							<Checkbox
								value={checkbox.label}
								isChecked={selectedItems.includes(checkbox.label) || false}
								onChange={handleChange}>
								{checkbox.label}
							</Checkbox>
						</div>
					))}
				</Box>
			</Flex>
			<Box className='dashboard_table'>
				<div className="App">
					<DataTable
						list={searchResults}
						loading={loading}
					/>
				</div>
				{
					searchResults.length > 0 && <Flex className='prev_next_buttons'>
						<IconButton
							colorScheme='transparent'
							aria-label='Search database'
							onClick={prevPage}
							icon={<ChevronLeftIcon />}
							mr={'10px'}
							color="#000"
							border={'1px'}
							borderColor={'#000'}
							borderRadius={'0'}
							fontSize={'30px'}
							isDisabled={page === 1}
						/>
						{/* {count} */}
						{/* {((count / page) < pageLimit).toString()} */}
						<IconButton
							colorScheme='transparent'
							aria-label='Search database'
							onClick={nextPage}
							icon={<ChevronRightIcon />}
							color="#000"
							border={'1px'}
							borderColor={'#000'}
							borderRadius={'0'}
							fontSize={'30px'}
							isDisabled={(count / page) <= pageLimit}
						/>
					</Flex>
				}

				<FitterDialog
					isOpen={isOpen}
					onClose={handleCloseModal}
					getData={handleFilterData}
					title='title'
					filterData={filter}
				/>
			</Box>
		</Box>
	);
};
export default Dashboard;