import React from 'react';
import { signInWithPopup, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { Container, Box, Text, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import googleIcon from '../../assets/images/google-icon.svg';
import siteLogo from '../../assets/images/logo.png';

const Login = () => {
	const provider = new GoogleAuthProvider();
	provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
	const auth = getAuth();
	const navigate = useNavigate();

	const toast = useToast();
	const _signIn = (auth: any, provider: any) => {
		signInWithPopup(auth, provider)
			.then(async(result) => {
				GoogleAuthProvider.credentialFromResult(result);
				localStorage.setItem('isLoggedIn', 'true');
				toast({
					title: 'Login ...',
					description: 'user login successfuly ....',
					status: 'success',
					duration: 1500,
					isClosable: true,
				});
				navigate('/');
			}).catch((error) => {
				console.log('error', error);

			});
	};

	return (
		<Box className="login_wrap">
			<Container maxW={['100%', '100%', '991px', '1280px', '1440px']}>
				<Box textAlign={'center'} mb={['35px', '50px']}>
					<Image
						mx={'auto'}
						src={siteLogo}
						alt='Logo'
					/>
				</Box>
				<Text
					fontWeight={'600'}
					mb={['50px', '100px']}
					fontSize={['38px', '46px', '54px']}
					lineHeight={'1.2'}
					textAlign={'center'}
					textTransform='uppercase'
					color='black'
				>
					Welcome to Clicbeat
				</Text>
				<Box textAlign={'center'} mb={'15px'}>
					<Text mb={'30px'} fontSize={['18px', '20px']}>Login to explore <Text as="span" fontWeight={'600'}>CLICBEAT.</Text></Text>
					<button onClick={() => {_signIn(auth, provider);}}><ReactSVG src={googleIcon} /> Google Login</button>
				</Box>
			</Container>
		</Box>
	);
};
export default Login;